import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import { getGlobalStreamConfigs, updateGlobalStreamConfig } from "../services/globalStreamConfigService";
import GlobalStreamConfigForm from "../components/Forms/GlobalStreamConfigForm";
import "../components/GlobalStreamConfig.scss";

class GlobalStreamConfigView extends Component {
  state = {
    streamConfigs: null,
    activeStreamConfigIndex: 0,
    isModalOpen: false,
    globalTargetRate: 1.0,
    showConfirmation: false,
  };

  async componentDidMount() {
    await this.fetchGlobalStreamConfigs();
  }

  handleApply = () => {
    this.setState({ showConfirmation: true });
  };

  handleConfirm = async () => {
    const { globalTargetRate } = this.state;
    const streamConfig = { global_target_rate: globalTargetRate };

    try {
      await updateGlobalStreamConfig(streamConfig);
      console.log("Global target rate saved successfully!");
    } catch (error) {
      console.error("Failed to save global target rate:", error);
    }

    this.setState({ showConfirmation: false });
    this.toggleModal();
  };

  handleCancel = () => {
    this.setState({ showConfirmation: false });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      showConfirmation: false,
    }));
  };

  handleTargetRateChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!Number.isNaN(value)) {
      this.setState({ globalTargetRate: value });
    }
  };

  toggle(streamConfigIndex) {
    const { activeStreamConfigIndex } = this.state;
    if (activeStreamConfigIndex !== streamConfigIndex) {
      this.setState({
        activeStreamConfigIndex: streamConfigIndex,
      });
    }
  }

  async fetchGlobalStreamConfigs() {
    const { data: streamConfigs } = await getGlobalStreamConfigs();
    const globalTargetRate = streamConfigs.length > 0 ? streamConfigs[0].global_target_rate : 1.0;

    this.setState({
      streamConfigs,
      globalTargetRate,
    });
  }

  render() {
    const { streamConfigs, activeStreamConfigIndex, isModalOpen, globalTargetRate, showConfirmation } = this.state;
    return (
      <div className="content">
        <Card>
          <CardHeader className="d-flex justify-content-between align-items-center">
            <h5 className="card-category">Stream configs</h5>
            <Button color="primary" onClick={this.toggleModal}>
              Update global target rate
            </Button>
          </CardHeader>
          {!streamConfigs && (
            <CardBody>
              <Spinner color="primary" />
            </CardBody>
          )}
          {streamConfigs && (
            <CardBody>
              <Nav tabs>
                {streamConfigs.map((streamConfig, streamConfigIndex) => (
                  <NavItem role="button" key={streamConfigIndex}>
                    <NavLink
                      className={activeStreamConfigIndex === streamConfigIndex ? "active" : ""}
                      onClick={() => {
                        this.toggle(streamConfigIndex);
                      }}
                    >
                      <p>{streamConfig.day}</p>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeStreamConfigIndex}>
                {streamConfigs.map((streamConfig, streamConfigIndex) => (
                  <TabPane key={streamConfig.day} tabId={streamConfigIndex}>
                    {streamConfig && <GlobalStreamConfigForm streamConfig={streamConfig} />}
                  </TabPane>
                ))}
              </TabContent>
            </CardBody>
          )}
        </Card>
        <Modal isOpen={isModalOpen} toggle={this.toggleModal} className="bg-dark text-light" contentClassName="bg-dark">
          <ModalHeader toggle={this.toggleModal} className="border-secondary">
            Update Global Target Rate
          </ModalHeader>
          <ModalBody className="bg-dark text-light">
            <div className="mb-3">
              <label>Global target rate:</label>
              <Input
                type="number"
                step="0.1"
                value={globalTargetRate}
                onChange={this.handleTargetRateChange}
                min="0.1"
                max="20"
                className="bg-dark text-light border-secondary"
              />
            </div>
          </ModalBody>
          <ModalFooter className="bg-dark border-secondary">
            {!showConfirmation ? (
              <Button onClick={this.handleApply}>
                <i className="fas fa-check mr-2" /> Apply
              </Button>
            ) : (
              <div className="">
                <Button color="success" onClick={this.handleConfirm}>
                  <i className="fas fa-save mr-2" /> Save
                </Button>
                <Button onClick={this.handleCancel}>
                  <i className="fas fa-times mr-2" /> Cancel
                </Button>
              </div>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default GlobalStreamConfigView;
